._1S8CwfI-lfqkReT7KUqUPR {

}

._1S8CwfI-lfqkReT7KUqUPR input {
  border: 1px solid rgba(var(--ids__text-RGB), 0.3);
}

._24OzsE7hyqw4FPviVWSxoT {
  display: flex;
  row-gap: 15px;
  flex-direction: column;
}

._24OzsE7hyqw4FPviVWSxoT button {
  background-color: #0050e1;
  color: #ffffff;
}

._24OzsE7hyqw4FPviVWSxoT button:hover {
  background-color: #007dff;
}

._2nvDTNRUPmDXZDzsNrHJM3 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: calc(var(--ids__density)* 1em / 2.5) calc(var(--ids__density)* 1em) calc(var(--ids__density)* 1em / 2.5);
  padding-left: calc(var(--ids__density)* 1em / 2);
  border: 1px solid rgba(var(--ids__text-RGB), 0.3);
  border-radius: 0.3em;
  background: transparent;
  color: RGB(var(--ids__text-RGB));
  line-height: 1;
}

._3aWng30BbbdV0gu0ob5yTM {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

._1MsFVzOiKHSpW18N2Wht6V {
  position: relative;
  display: flex;
  padding-right: 5px;
  column-gap: 5px;
}

._ZC-uPz2WJ5SnnsT0wGG8 {
  position: absolute;
  top: 40px;
  left: -16px;
  background: #ffffff;
  border-radius: 8px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 250px;
  border: 1px solid RGB(var(--ids__text-RGB));
  padding-top: 10px;
  padding-bottom: 10px;
}

._2F3pRzf2u0B68FmFuCamdW {
  font-size: 18px;
  padding-left: 10px;
  padding-bottom: 8px;
}

._1VxHnTU876CYmdJwjyFcOI {
  user-select: none;
  cursor: pointer;
  display: flex;
  column-gap: 8px;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

._1VxHnTU876CYmdJwjyFcOI:hover {
  background: #F7FAFC;
}

._1VxHnTU876CYmdJwjyFcOI img {
  width: 22px;
}

._2ryQCimrhudD3tzEtF9eHr {
  display: flex;
  column-gap: 3px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  width: max-content;
  line-height: 1px;
}

.Hqn-g-75XSr6_0JrPJ0IO{
  width: 16px;
  height: 16px;
}

.piztSRZwh4cVv6nbxLvO8 {
  background-color: #005aff;
}

._3mN4SrpGW1cruTUMIhgerO {
  position: relative;
}

.YDTTfer16YDwrNyo9ymNf {
  position: absolute;
  top: 26%;
  right: 14px;
}

.YDTTfer16YDwrNyo9ymNf img {
  width: 24px;
}

.LWHy3WgK25TMRkjmzIdIo {
  font-size: 0.7em;
}

.sEgQMVDX559qEcq2rfSVu {
  color: #F1182F;
}

._26WVGDpEpe9XWUowhyQc9v {
  border-color: #F1182F;
}

._2ukUomusVRGU--0iRMm2Qy {
  max-width: 400px;
}

._2DY5tBMR0Ie8Cx3mQRbYGZ {
  font-size: 0.7em !important;
}

._2ukUomusVRGU--0iRMm2Qy button {
  background-color: #0050e1;
  color: #ffffff;
}

._2ukUomusVRGU--0iRMm2Qy button:hover {
  background-color: #007dff;
}

._3TttTzrevm-YPnamVhNpWV::-webkit-outer-spin-button,
._3TttTzrevm-YPnamVhNpWV::-webkit-inner-spin-button{
  -webkit-appearance: none;
}

._3TttTzrevm-YPnamVhNpWV[type=number] {
  -moz-appearance: textfield;
}

._2ukUomusVRGU--0iRMm2Qy ._25ZTwvKJltjzMULBuzMKcw {
  padding: 0;
  background: transparent;
  opacity: 0.7;
  margin-bottom: 14px;
  font-size: 16px;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

._2ukUomusVRGU--0iRMm2Qy ._25ZTwvKJltjzMULBuzMKcw:hover {
  background: transparent;
  opacity: 1;
}

._2ukUomusVRGU--0iRMm2Qy ._25ZTwvKJltjzMULBuzMKcw img{
  width: 16px;
}

._2Sq9hhRREphPlDplT32CT4 {
  margin-bottom: 14px;
}

.QAbcqSz4iscWK9G9XyQJc {
  font-size: 16px;
  margin-bottom: 14px;
  opacity: 0.7;
}

._3zou-Z7K46VZcylc8YgkbS {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

._3zou-Z7K46VZcylc8YgkbS label {
  font-size: 16px;
  font-weight: 600;
}

._2B0Xr64urGbnxPrnm7yiro {
  display: flex;
  justify-content: space-between;
}

._3g7A9XaMVQYPhgdSJOwlWD {
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 16px;
  color: #0059FF !important;
}
